* {
	margin: 0;
	padding: 0;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

html,
body {
	position: relative;
	height: 100%;
}

body {
	background: #eee;
	font-size: 14px;
	color: #000;
}

button {
	border: none;
	background-color: transparent;
	outline: none;
}