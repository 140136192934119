@media (max-width: 1000px) {
  .header {
    display: none;
  }

  .swiper {
    .page1 {
      @include bgImg("../images/mobile/page1/bg.jpeg", 100vw, 100vh);

      .center {
        @include bgImg("../images/mobile/page1/center.png", 100%, 80%);

        position: relative;
        margin-top: -4%;
      }
  
      .logo {
        @include bgImg("../images/mobile/page1/logo.png", 100px, 100px);

        position: absolute;
        top: -25px;
        margin: 0;
      }
  
      .desc {
        @include bgImg("../images/mobile/page1/desc.png", 203px, 134px);
        margin: 38% 0 0 0;
      }

      .download-button,
      .footer {
        display: none;
      }
    }

    .page2,
    .page3,
    .page4,
    .page5 {
      .left {
        background: unset;
      }

      .right {
        width: 100vw;
        padding-left: 20.7%;
      }

      .title {
        margin: 10% 0 15px;
        width: 233px;
        height: auto;
      }

      .desc {
        margin: 0;
        font-family: PingFangSC-Light;
        font-size: 14px;
        color: #666666;
        line-height: 26px;
      }
    }

    .page2 {
      @include bgImg("../images/mobile/page2/bg.jpeg", 100vw, 100vh);

      flex-direction: column-reverse;
      position: relative;
      padding: 0;

      &::before {
        @include bgImg("../images/mobile/page2/center.png", 358px, 358px);

        position: absolute;
        top: 25.7%;
        left: 50%;
        margin-left: -179px;
        content: '';
      }

      .screen {
        @include bgImg("../images/mobile/page2/screen.png", 256px, 485px);
    
        position: absolute;
        bottom: 0;
        transform-origin: bottom;
      }


      .icon1 {
        @include bgImg("../images/mobile/page2/icon1.png", 100px, 105px);
    
        position: absolute;
        top: -28px;
        left: -30px;
      }

      .icon2 {
        width: 80px;
        height: 80px;
        padding: 22px;
        position: absolute;
        top: 192px;
        right: -52px;
      }
    }

    .page3 {
      @include bgImg("../images/mobile/page2/bg.jpeg", 100vw, 100vh);

      flex-direction: column-reverse;
      position: relative;
      padding: 0;

      &::before {
        @include bgImg("../images/mobile/page2/center.png", 358px, 358px);

        position: absolute;
        top: 25.7%;
        left: 50%;
        margin-left: -179px;
        content: '';
      }

      .screen {
        @include bgImg("../images/mobile/page3/screen.png", 256px, 476px);
    
        position: absolute;
        bottom: 0;
        transform-origin: bottom;
      }

      .icon1 {
        @include bgImg("../images/mobile/page3/icon1.png", 106px, 133px);
    
        position: absolute;
        top: -70px;
        right: -32px;
      }
    
      .icon2 {
        @include bgImg("../images/mobile/page3/icon2.png", 80px, 87px);
    
        position: absolute;
        top: 241px;
        left: -32px;
      }
    }

    .page4 {
      @include bgImg("../images/mobile/page4/bg.jpeg", 100vw, 100vh);

      flex-direction: column-reverse;
      position: relative;
      padding: 0;

      &::before {
        @include bgImg("../images/mobile/page2/center.png", 358px, 358px);

        position: absolute;
        top: 25.7%;
        left: 50%;
        margin-left: -179px;
        content: '';
      }

      .screen {
        @include bgImg("../images/mobile/page4/screen.png", 256px, 476px);
    
        position: absolute;
        bottom: 0;
        transform-origin: bottom;
      }

      .icon1 {
        @include bgImg("../images/mobile/page4/icon1.png", 116px, 125px);
    
        position: absolute;
        top: -54px;
        right: -33px;
      }
    
      .icon2 {
        @include bgImg("../images/mobile/page4/icon2.png", 139px, 134px);
    
        position: absolute;
        top: 179px;
        left: -55px;
      }
    }

    .page5 {
      @include bgImg("../images/mobile/page5/bg.jpeg", 100vw, 100vh);

      flex-direction: column-reverse;
      position: relative;
      padding: 0;

      &::before {
        @include bgImg("../images/mobile/page2/center.png", 358px, 358px);

        position: absolute;
        top: 25.7%;
        left: 50%;
        margin-left: -179px;
        content: '';
      }

      .screen {
        @include bgImg("../images/mobile/page5/screen.png", 256px, 466px);
    
        position: absolute;
        bottom: 0;
        transform-origin: bottom;
      }

      .icon1 {
        @include bgImg("../images/mobile/page5/icon1.png", 100px, 100px);
    
        position: absolute;
        top: -46px;
        right: -35px;
        left: unset;
      }
    
      .icon2 {
        @include bgImg("../images/mobile/page5/icon2.png", 128px, 130px);
    
        position: absolute;
        top: 170px;
        left: -41px;
      }

      .icon3 {
        display: none;
      }
    }
  }

  button.mobile-download {
    @include bgImg("../images/mobile/bg-button.png", 208px, 60px);

    display: block;
    position: fixed;
    left: 50%;
    margin-left: -104px;
    bottom: 14.6%;
    z-index: 10;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
  }

  .other .mobile-icon-down {
		@include bgImg("../images/mobile/icon-down-withe.png", 21px, 18px);

		position: fixed;
    display: block;
		left: 50%;
		margin-left: -10.5px;
		bottom: 3.6%;
    z-index: 10;
	}

  .icon-down {
    display: none;
  }

  body[data-active="active-1"],
  body[data-active="active-2"] {
    .mobile-icon-down {
      @include bgImg("../images/mobile/icon-down.png", 16px, 13px);
    }
  }

  body[data-active="active-4"] {
    .mobile-icon-down {
      display: none;
    }
  }
}

@media (max-height: 700px) {
  .swiper {
    .page2,
    .page3,
    .page4,
    .page5 {
      .screen {
        transform: scale(0.85);
      }
    }
  }
}

@media (max-height: 603px) {
  .swiper {
    .page2,
    .page3,
    .page4,
    .page5 {
      .title {
        margin: 15px 0 7px;
      }
      .screen {
        transform: scale(0.75);
      }
    }
  }
}