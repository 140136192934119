.swiper {
	width: 100%;
	height: 100%;

	.swiper-pagination-bullets {
		right: 40px !important;
	}

  .swiper-pagination-bullet {
    width: 4.69px;
    height: 4.69px;
    background-color: rgba(51,51,51, 0.2);
    opacity: unset;
  }

  .swiper-pagination-bullet-active {
    background-color: #333;
    height: 12.5px;
    border-radius: 4.3px;

		&:first-child {
			background-color: #fff;

			& ~ .swiper-pagination-bullet {
				background-color: rgba(255,255,255, 0.2);
			}
		}
  }
}

.swiper-wrapper {
	position: relative;
}

.swiper-slide {
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

@media (max-width: 1000px) {
	.swiper {
	
		.swiper-pagination-bullets {
			display: none;
		}
	}
}

.swiper-slide  .right {
	margin-top: 50px;
}

.swiper-slide-active .title {
	-webkit-transform: translate3d(0,-50px,0);
	transform: translate3d(0,-50px,0);
	-webkit-transition-timing-function: cubic-bezier(.25,.1,.25,1);
	transition-timing-function: cubic-bezier(.25,.1,.25,1);
	-webkit-transition: all 500ms;
	transition: all 500ms;
}

.swiper-slide-active p.desc {
	-webkit-transform: translate3d(0,-50px,0);
	transform: translate3d(0,-50px,0);
	-webkit-transition-timing-function: cubic-bezier(.25,.1,.25,1);
	transition-timing-function: cubic-bezier(.25,.1,.25,1);
	-webkit-transition: all 800ms;
	transition: all 800ms;
}

.swiper-slide-active .screen {
	margin-top: -20px;
}

.swiper-slide-active .screen {
	-webkit-transform: translate3d(0,-20px,0);
	transform: translate3d(0,-20px,0);
	-webkit-transition-timing-function: cubic-bezier(.25,.1,.25,1);
	transition-timing-function: cubic-bezier(.25,.1,.25,1);
	-webkit-transition: all 500ms;
	transition: all 500ms;
	transition-delay: .1s;
}

.swiper-slide-active .screen__inner {
	transform: scale3d(1,1,1);
	transition-duration: 550ms;
	transition-delay: .1s;
}