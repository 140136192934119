.header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 33.6px 282px 0;
	z-index: 9;
}

.logo {
	@include bgImg("../images/logo.png", 64.5px, 46.5px);
}

.nav {
	display: flex;
	align-items: center;
}

.nav-item {
	font-size: 14.06px;
	color: #9B9B9B;
	margin-right: 63px;

	&:last-child {
		margin-right: 0;
	}

	&.active {
		color: #333;
	}
}

body[data-active="active-0"] {
	.header {
		opacity: 0;
	}
}