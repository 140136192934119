* {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-size: 14px;
  color: #000;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33.6px 282px 0;
  z-index: 9;
}

.logo {
  background: url("logo.c1dd22bd.png") 0 0 no-repeat;
  width: 64.5px;
  height: 46.5px;
  background-size: 64.5px 46.5px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-item {
  font-size: 14.06px;
  color: #9B9B9B;
  margin-right: 63px;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-item.active {
  color: #333;
}

body[data-active=active-0] .header {
  opacity: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper .swiper-pagination-bullets {
  right: 40px !important;
}

.swiper .swiper-pagination-bullet {
  width: 4.69px;
  height: 4.69px;
  background-color: rgba(51, 51, 51, 0.2);
  opacity: unset;
}

.swiper .swiper-pagination-bullet-active {
  background-color: #333;
  height: 12.5px;
  border-radius: 4.3px;
}

.swiper .swiper-pagination-bullet-active:first-child {
  background-color: #fff;
}

.swiper .swiper-pagination-bullet-active:first-child ~ .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.2);
}

.swiper-wrapper {
  position: relative;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .swiper .swiper-pagination-bullets {
    display: none;
  }
}

.swiper-slide .right {
  margin-top: 50px;
}

.swiper-slide-active .title {
  -webkit-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.swiper-slide-active p.desc {
  -webkit-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transition: all 800ms;
  transition: all 800ms;
}

.swiper-slide-active .screen {
  margin-top: -20px;
}

.swiper-slide-active .screen {
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transition: all 500ms;
  transition: all 500ms;
  transition-delay: 0.1s;
}

.swiper-slide-active .screen__inner {
  transform: scale3d(1, 1, 1);
  transition-duration: 550ms;
  transition-delay: 0.1s;
}

@media (max-width: 1000px) {
  .header {
    display: none;
  }

  .swiper .page1 {
    background: url("bg.e12d0904.jpeg") 0 0 no-repeat;
    width: 100vw;
    height: 100vh;
    background-size: 100vw 100vh;
  }
  .swiper .page1 .center {
    background: url("center.e17f68d0.png") 0 0 no-repeat;
    width: 100%;
    height: 80%;
    background-size: 100% 80%;
    position: relative;
    margin-top: -4%;
  }
  .swiper .page1 .logo {
    background: url("logo.795053ab.png") 0 0 no-repeat;
    width: 100px;
    height: 100px;
    background-size: 100px 100px;
    position: absolute;
    top: -25px;
    margin: 0;
  }
  .swiper .page1 .desc {
    background: url("desc.6407d5e2.png") 0 0 no-repeat;
    width: 203px;
    height: 134px;
    background-size: 203px 134px;
    margin: 38% 0 0 0;
  }
  .swiper .page1 .download-button,
.swiper .page1 .footer {
    display: none;
  }
  .swiper .page2 .left,
.swiper .page3 .left,
.swiper .page4 .left,
.swiper .page5 .left {
    background: unset;
  }
  .swiper .page2 .right,
.swiper .page3 .right,
.swiper .page4 .right,
.swiper .page5 .right {
    width: 100vw;
    padding-left: 20.7%;
  }
  .swiper .page2 .title,
.swiper .page3 .title,
.swiper .page4 .title,
.swiper .page5 .title {
    margin: 10% 0 15px;
    width: 233px;
    height: auto;
  }
  .swiper .page2 .desc,
.swiper .page3 .desc,
.swiper .page4 .desc,
.swiper .page5 .desc {
    margin: 0;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666666;
    line-height: 26px;
  }
  .swiper .page2 {
    background: url("bg.f4d88b08.jpeg") 0 0 no-repeat;
    width: 100vw;
    height: 100vh;
    background-size: 100vw 100vh;
    flex-direction: column-reverse;
    position: relative;
    padding: 0;
  }
  .swiper .page2::before {
    background: url("center.abb7d489.png") 0 0 no-repeat;
    width: 358px;
    height: 358px;
    background-size: 358px 358px;
    position: absolute;
    top: 25.7%;
    left: 50%;
    margin-left: -179px;
    content: "";
  }
  .swiper .page2 .screen {
    background: url("screen.773f087e.png") 0 0 no-repeat;
    width: 256px;
    height: 485px;
    background-size: 256px 485px;
    position: absolute;
    bottom: 0;
    transform-origin: bottom;
  }
  .swiper .page2 .icon1 {
    background: url("icon1.5554ec5c.png") 0 0 no-repeat;
    width: 100px;
    height: 105px;
    background-size: 100px 105px;
    position: absolute;
    top: -28px;
    left: -30px;
  }
  .swiper .page2 .icon2 {
    width: 80px;
    height: 80px;
    padding: 22px;
    position: absolute;
    top: 192px;
    right: -52px;
  }
  .swiper .page3 {
    background: url("bg.f4d88b08.jpeg") 0 0 no-repeat;
    width: 100vw;
    height: 100vh;
    background-size: 100vw 100vh;
    flex-direction: column-reverse;
    position: relative;
    padding: 0;
  }
  .swiper .page3::before {
    background: url("center.abb7d489.png") 0 0 no-repeat;
    width: 358px;
    height: 358px;
    background-size: 358px 358px;
    position: absolute;
    top: 25.7%;
    left: 50%;
    margin-left: -179px;
    content: "";
  }
  .swiper .page3 .screen {
    background: url("screen.1e00e9e0.png") 0 0 no-repeat;
    width: 256px;
    height: 476px;
    background-size: 256px 476px;
    position: absolute;
    bottom: 0;
    transform-origin: bottom;
  }
  .swiper .page3 .icon1 {
    background: url("icon1.1dda9552.png") 0 0 no-repeat;
    width: 106px;
    height: 133px;
    background-size: 106px 133px;
    position: absolute;
    top: -70px;
    right: -32px;
  }
  .swiper .page3 .icon2 {
    background: url("icon2.e1b27e38.png") 0 0 no-repeat;
    width: 80px;
    height: 87px;
    background-size: 80px 87px;
    position: absolute;
    top: 241px;
    left: -32px;
  }
  .swiper .page4 {
    background: url("bg.1d73f51a.jpeg") 0 0 no-repeat;
    width: 100vw;
    height: 100vh;
    background-size: 100vw 100vh;
    flex-direction: column-reverse;
    position: relative;
    padding: 0;
  }
  .swiper .page4::before {
    background: url("center.abb7d489.png") 0 0 no-repeat;
    width: 358px;
    height: 358px;
    background-size: 358px 358px;
    position: absolute;
    top: 25.7%;
    left: 50%;
    margin-left: -179px;
    content: "";
  }
  .swiper .page4 .screen {
    background: url("screen.e32d2391.png") 0 0 no-repeat;
    width: 256px;
    height: 476px;
    background-size: 256px 476px;
    position: absolute;
    bottom: 0;
    transform-origin: bottom;
  }
  .swiper .page4 .icon1 {
    background: url("icon1.60247496.png") 0 0 no-repeat;
    width: 116px;
    height: 125px;
    background-size: 116px 125px;
    position: absolute;
    top: -54px;
    right: -33px;
  }
  .swiper .page4 .icon2 {
    background: url("icon2.06c30646.png") 0 0 no-repeat;
    width: 139px;
    height: 134px;
    background-size: 139px 134px;
    position: absolute;
    top: 179px;
    left: -55px;
  }
  .swiper .page5 {
    background: url("bg.2c4c86b5.jpeg") 0 0 no-repeat;
    width: 100vw;
    height: 100vh;
    background-size: 100vw 100vh;
    flex-direction: column-reverse;
    position: relative;
    padding: 0;
  }
  .swiper .page5::before {
    background: url("center.abb7d489.png") 0 0 no-repeat;
    width: 358px;
    height: 358px;
    background-size: 358px 358px;
    position: absolute;
    top: 25.7%;
    left: 50%;
    margin-left: -179px;
    content: "";
  }
  .swiper .page5 .screen {
    background: url("screen.dac0f46d.png") 0 0 no-repeat;
    width: 256px;
    height: 466px;
    background-size: 256px 466px;
    position: absolute;
    bottom: 0;
    transform-origin: bottom;
  }
  .swiper .page5 .icon1 {
    background: url("icon1.cb0dfdba.png") 0 0 no-repeat;
    width: 100px;
    height: 100px;
    background-size: 100px 100px;
    position: absolute;
    top: -46px;
    right: -35px;
    left: unset;
  }
  .swiper .page5 .icon2 {
    background: url("icon2.b9af5204.png") 0 0 no-repeat;
    width: 128px;
    height: 130px;
    background-size: 128px 130px;
    position: absolute;
    top: 170px;
    left: -41px;
  }
  .swiper .page5 .icon3 {
    display: none;
  }

  button.mobile-download {
    background: url("bg-button.01053042.png") 0 0 no-repeat;
    width: 208px;
    height: 60px;
    background-size: 208px 60px;
    display: block;
    position: fixed;
    left: 50%;
    margin-left: -104px;
    bottom: 14.6%;
    z-index: 10;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
  }

  .other .mobile-icon-down {
    background: url("icon-down-withe.acc8b2e4.png") 0 0 no-repeat;
    width: 21px;
    height: 18px;
    background-size: 21px 18px;
    position: fixed;
    display: block;
    left: 50%;
    margin-left: -10.5px;
    bottom: 3.6%;
    z-index: 10;
  }

  .icon-down {
    display: none;
  }

  body[data-active=active-1] .mobile-icon-down,
body[data-active=active-2] .mobile-icon-down {
    background: url("icon-down.90586ae6.png") 0 0 no-repeat;
    width: 16px;
    height: 13px;
    background-size: 16px 13px;
  }

  body[data-active=active-4] .mobile-icon-down {
    display: none;
  }
}

@media (max-height: 700px) {
  .swiper .page2 .screen,
.swiper .page3 .screen,
.swiper .page4 .screen,
.swiper .page5 .screen {
    transform: scale(0.85);
  }
}

@media (max-height: 603px) {
  .swiper .page2 .title,
.swiper .page3 .title,
.swiper .page4 .title,
.swiper .page5 .title {
    margin: 15px 0 7px;
  }
  .swiper .page2 .screen,
.swiper .page3 .screen,
.swiper .page4 .screen,
.swiper .page5 .screen {
    transform: scale(0.75);
  }
}

.page1 {
  background: url("bg.411bf39b.jpeg") 0 0 no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 100vh;
  flex-direction: column;
}

.page1 .center {
  background: url("center.f570d005.png") 0 0 no-repeat;
  width: 723px;
  height: 723px;
  background-size: 723px 723px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page1 .logo {
  background: url("logo.9c906061.png") 0 0 no-repeat;
  width: 109px;
  height: 109px;
  background-size: 109px 109px;
  margin: 10px 0 192px;
}

.page1 .desc {
  background: url("desc.94d81d7f.png") 0 0 no-repeat;
  width: 553px;
  height: 85px;
  background-size: 553px 85px;
  margin: 0 0 150px;
}

.page1 .download-button {
  background: url("bg-button.de6ea6fa.png") 0 0 no-repeat;
  width: 234px;
  height: 64px;
  background-size: 234px 64px;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
}

.page1 .download-button:first-child {
  margin-right: 62.5px;
}

.page1 .icon-android,
.page1 .icon-iOS {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  vertical-align: top;
}

.page1 .footer {
  font-family: PingFangSC-Regular;
  font-size: 9.38px;
  color: rgba(178, 178, 178, 0.4);
  letter-spacing: 0;
  text-align: center;
}

.page1 .footer p {
  margin-bottom: 7.8px;
}

.page1 .footer p:last-child {
  margin-bottom: 0;
}

.page1 .footer a {
  color: inherit;
  text-decoration: unset;
}

.page1 .footer a:hover {
  text-decoration: underline;
}

.page2 {
  background: url("bg.1dc6212f.jpeg") 0 0 no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 100vh;
  padding: 68px 139px 44.5px;
  box-sizing: border-box;
}

.page2 .left {
  background: url("left.05da55ac.png") 0 0 no-repeat;
  width: 723px;
  height: 723px;
  background-size: 723px 723px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.page2 .screen {
  background: url("screen.d4db6975.png") 0 0 no-repeat;
  width: 250px;
  height: 503px;
  background-size: 250px 503px;
  position: relative;
}

.page2 .icon1 {
  background: url("icon1.d0535066.png") 0 0 no-repeat;
  width: 109px;
  height: 114px;
  background-size: 109px 114px;
  position: absolute;
  top: 75px;
  left: -68px;
}

.page2 .icon2 {
  width: 78px;
  height: 78px;
  padding: 22px;
  position: absolute;
  right: -52px;
  bottom: 19px;
}

.page2 .right {
  width: 35%;
}

.page2 .title {
  width: 185px;
  height: auto;
}

.page2 .desc {
  margin-top: 22.66px;
  font-family: PingFangSC-Light;
  font-size: 18.75px;
  color: #666666;
  line-height: 35.94px;
}

.page3 {
  background: url("bg.1dc6212f.jpeg") 0 0 no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 100vh;
  padding: 68px 139px 44.5px;
  box-sizing: border-box;
}

.page3 .left {
  background: url("left.05da55ac.png") 0 0 no-repeat;
  width: 723px;
  height: 723px;
  background-size: 723px 723px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.page3 .screen {
  background: url("screen.be0c5991.png") 0 0 no-repeat;
  width: 250px;
  height: 503px;
  background-size: 250px 503px;
  position: relative;
}

.page3 .icon1 {
  background: url("icon1.e01f6fbd.png") 0 0 no-repeat;
  width: 111px;
  height: 141px;
  background-size: 111px 141px;
  position: absolute;
  top: 38px;
  right: -59px;
}

.page3 .icon2 {
  background: url("icon2.42f4a628.png") 0 0 no-repeat;
  width: 78px;
  height: 85px;
  background-size: 78px 85px;
  position: absolute;
  left: -40px;
  bottom: 44px;
}

.page3 .right {
  width: 35%;
}

.page3 .title {
  width: 182px;
  height: auto;
}

.page3 .desc {
  margin-top: 22.66px;
  font-family: PingFangSC-Light;
  font-size: 18.75px;
  color: #666666;
  line-height: 35.94px;
}

.page4 {
  background: url("bg.1dc6212f.jpeg") 0 0 no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 100vh;
  padding: 68px 139px 44.5px;
  box-sizing: border-box;
}

.page4 .left {
  background: url("left.05da55ac.png") 0 0 no-repeat;
  width: 723px;
  height: 723px;
  background-size: 723px 723px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.page4 .screen {
  background: url("screen.9222ea48.png") 0 0 no-repeat;
  width: 250px;
  height: 503px;
  background-size: 250px 503px;
  position: relative;
}

.page4 .icon1 {
  background: url("icon1.857dc70e.png") 0 0 no-repeat;
  width: 90px;
  height: 97px;
  background-size: 90px 97px;
  position: absolute;
  top: 78px;
  left: -32px;
}

.page4 .icon2 {
  background: url("icon2.52547a79.png") 0 0 no-repeat;
  width: 87px;
  height: 124px;
  background-size: 87px 124px;
  position: absolute;
  right: -10px;
  bottom: 78px;
}

.page4 .right {
  width: 35%;
}

.page4 .title {
  width: 185px;
  height: auto;
}

.page4 .desc {
  margin-top: 22.66px;
  font-family: PingFangSC-Light;
  font-size: 18.75px;
  color: #666666;
  line-height: 35.94px;
}

.page5 {
  background: url("bg.1dc6212f.jpeg") 0 0 no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 100vh;
  padding: 68px 139px 44.5px;
  box-sizing: border-box;
}

.page5 .left {
  background: url("left.05da55ac.png") 0 0 no-repeat;
  width: 723px;
  height: 723px;
  background-size: 723px 723px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.page5 .screen {
  background: url("screen.f3e941ce.png") 0 0 no-repeat;
  width: 250px;
  height: 503px;
  background-size: 250px 503px;
  position: relative;
}

.page5 .icon1 {
  background: url("icon1.38840ce9.png") 0 0 no-repeat;
  width: 100px;
  height: 101px;
  background-size: 100px 101px;
  position: absolute;
  top: 53px;
  left: -46px;
}

.page5 .icon2 {
  background: url("icon2.56b21443.png") 0 0 no-repeat;
  width: 78px;
  height: 78px;
  background-size: 78px 78px;
  position: absolute;
  top: 171px;
  right: -34px;
}

.page5 .icon3 {
  background: url("icon3.cef6c9da.png") 0 0 no-repeat;
  width: 245px;
  height: 167px;
  background-size: 245px 167px;
  position: absolute;
  left: 50%;
  margin-left: -122.5px;
  bottom: -35px;
}

.page5 .right {
  width: 35%;
}

.page5 .title {
  width: 185px;
  height: 43px;
}

.page5 .desc {
  margin-top: 22.66px;
  font-family: PingFangSC-Light;
  font-size: 18.75px;
  color: #666666;
  line-height: 35.94px;
}

.page2,
.page3,
.page4 {
  position: relative;
}

.page2 .icon-down,
.page3 .icon-down,
.page4 .icon-down {
  background: url("icon-down.990cee2c.png") 0 0 no-repeat;
  width: 16px;
  height: 14px;
  background-size: 16px 14px;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  bottom: 52px;
}

.mobile-download,
.mobile-icon-down {
  display: none;
}

.screen__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale3d(0.6, 0.6, 0.6);
}
/*# sourceMappingURL=index.6629666f.css.map */
