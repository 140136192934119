@import './common.scss';
@import './header.scss';
@import './swiper.scss';
@import './index.mobile.scss';


.page1 {
	@include bgImg("../images/page1/bg.jpeg", 100vw, 100vh);

	flex-direction: column;

	.center {
		@include bgImg("../images/page1/center.png", 723px, 723px);

		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center
	}

	.logo {
		@include bgImg("../images/page1/logo.png", 109px, 109px);

		margin: 10px 0 192px;
	}

	.desc {
		@include bgImg("../images/page1/desc.png", 553px, 85px);

		margin: 0 0 150px
	}

	.download-button {
		@include bgImg("../images/page1/bg-button.png", 234px, 64px);

		&:first-child {
			margin-right: 62.5px;
		}

		font-size: 12px;
		color: #FFFFFF;
		letter-spacing: 0;
		text-align: center;
		cursor: pointer;
	}

	.icon-android,
	.icon-iOS {
		width: 14px;
		height: 14px;
		margin-right: 4px;
		vertical-align: top;
	}

	.footer {
		font-family: PingFangSC-Regular;
		font-size: 9.38px;
		color: rgba(178,178,178, 0.4);
		letter-spacing: 0;
		text-align: center;

		p {
			margin-bottom: 7.8px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: inherit;
			text-decoration: unset;

			&:hover {
				text-decoration:underline;
			}
		}
	}
}

.page2 {
	@include bgImg("../images/page2/bg.jpeg", 100vw, 100vh);

	padding: 68px 139px 44.5px;
	box-sizing: border-box;

	.left {
		@include bgImg("../images/page2/left.png", 723px, 723px);

		display: flex;
		align-items: center;
		justify-content: center;
		background-position: center;
	}

	.screen {
		@include bgImg("../images/page2/screen.png", 250px, 503px);

		position: relative;
	}

	.icon1 {
		@include bgImg("../images/page2/icon1.png", 109px, 114px);

		position: absolute;
		top: 75px;
		left: -68px;
	}

	.icon2 {
		width: 78px;
		height: 78px;
		padding: 22px;
		position: absolute;
		right: -52px;
		bottom: 19px;
	}

	.right {
		width: 35%;
	}

	.title {
		width: 185px;
		height: auto;
	}

	.desc {
		margin-top: 22.66px;
		font-family: PingFangSC-Light;
		font-size: 18.75px;
		color: #666666;
		line-height: 35.94px;
	}
}

.page3 {
	@include bgImg("../images/page2/bg.jpeg", 100vw, 100vh);

	padding: 68px 139px 44.5px;
	box-sizing: border-box;

	.left {
		@include bgImg("../images/page2/left.png", 723px, 723px);

		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background-position: center;
	}

	.screen {
		@include bgImg("../images/page3/screen.png", 250px, 503px);

		position: relative;
	}

	.icon1 {
		@include bgImg("../images/page3/icon1.png", 111px, 141px);

		position: absolute;
		top: 38px;
		right: -59px;
	}

	.icon2 {
		@include bgImg("../images/page3/icon2.png", 78px, 85px);

		position: absolute;
		left: -40px;
		bottom: 44px;
	}

	.right {
		width: 35%;
	}

	.title {
		width: 182px;
		height: auto;
	}

	.desc {
		margin-top: 22.66px;
		font-family: PingFangSC-Light;
		font-size: 18.75px;
		color: #666666;
		line-height: 35.94px;
	}
}

.page4 {
	@include bgImg("../images/page2/bg.jpeg", 100vw, 100vh);

	padding: 68px 139px 44.5px;
	box-sizing: border-box;

	.left {
		@include bgImg("../images/page2/left.png", 723px, 723px);

		display: flex;
		align-items: center;
		justify-content: center;
		background-position: center;
	}

	.screen {
		@include bgImg("../images/page4/screen.png", 250px, 503px);

		position: relative;
	}

	.icon1 {
		@include bgImg("../images/page4/icon1.png", 90px, 97px);

		position: absolute;
		top: 78px;
		left: -32px;
	}

	.icon2 {
		@include bgImg("../images/page4/icon2.png", 87px, 124px);

		position: absolute;
		right: -10px;
		bottom: 78px;
	}

	.right {
		width: 35%;
	}

	.title {
		width: 185px;
		height: auto;
	}

	.desc {
		margin-top: 22.66px;
		font-family: PingFangSC-Light;
		font-size: 18.75px;
		color: #666666;
		line-height: 35.94px;
	}
}

.page5 {
	@include bgImg("../images/page2/bg.jpeg", 100vw, 100vh);

	padding: 68px 139px 44.5px;
	box-sizing: border-box;

	.left {
		@include bgImg("../images/page2/left.png", 723px, 723px);

		display: flex;
		align-items: center;
		justify-content: center;
		background-position: center;
	}

	.screen {
		@include bgImg("../images/page5/screen.png", 250px, 503px);

		position: relative;
	}

	.icon1 {
		@include bgImg("../images/page5/icon1.png", 100px, 101px);

		position: absolute;
		top: 53px;
		left: -46px;
	}

	.icon2 {
		@include bgImg("../images/page5/icon2.png", 78px, 78px);

		position: absolute;
		top: 171px;
		right: -34px;
	}
	
	.icon3 {
		@include bgImg("../images/page5/icon3.png", 245px, 167px);

		position: absolute;
		left: 50%;
		margin-left: -122.5px;
		bottom: -35px;
	}

	.right {
		width: 35%;
	}

	.title {
		width: 185px;
		height: 43px;
	}

	.desc {
		margin-top: 22.66px;
		font-family: PingFangSC-Light;
		font-size: 18.75px;
		color: #666666;
		line-height: 35.94px;
	}
}

.page2,
.page3,
.page4 {
	position: relative;

	.icon-down {
		@include bgImg("../images/icon-down.png", 16px, 14px);

		position: absolute;
		left: 50%;
		margin-left: -7px;
		bottom: 52px;
	}
}


.mobile-download,
.mobile-icon-down {
	display: none;
}

.screen__inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transform: scale3d(0.6, 0.6, 0.6);
}